@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap");
body.light-theme {
  background-color: #fafafa;
  color: #d8ba25;
  transition: background-color 0.3s ease;
  --color-text: "#333";
}
body.dark-theme {
  background-color: #1a1919;
  color: #fff;
  --color-text: #ffffff;
  color: white;
}
