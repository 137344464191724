:root {
  --primary: #597593;
  --secondary: #597593;
  --background: #fafafa;
  --fontcolor: #333;
}

.App {
  font-family: sans-serif;
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}
.arrow-btn {
  /* top: 45%;   */
  z-index: 1;
}
.next {
  float: right;
}
.slick-dots li button::before {
  font-size: 10px;
  color: #fff;
  opacity: 0.7;
}

.slick-dots li.slick-active button::before {
  color: var(--primary);
  opacity: 1;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  width: 100%;
}
